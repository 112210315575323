

export default [

  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE LOGIN */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* para el login de inicio */
  {
    path: "/login",
    name: "login",
    meta: {
      title: "Bienvenidos a Helios",
    },
    component: () => import("../views/account/login.vue"),
    
  },

  /* ruta para registrarse en el sistema */
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
  
  },



  /* ruta para entrar al panel inicial*/
  {
    path: "/dashboard/crypto",
    name: "dashboard-crypto",
    meta: {
      title: "Panel",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/dashboard/crypto/index"),
  },
  /* ///////////////////////////////////////////////////////////////////////////////////// */

  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE DIRECTORIO */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* ruta para la seccion del directorio */
  {
    path: "/directorio",
    name: "directorio",
    meta: {
      title: "Directorio",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/directorio/index"),
  },

 
  /* ///////////////////////////////////////////////////////////////////////////////////// */
 
  
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE PRODUCTOS */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* concentraod de producto */
  {
    path: "/productos/listado",
    name: "productos",
    meta: {
      title: "Productos",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/productos/listado"),
  },
  
  /* seccion general de dashboard */
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },

  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE EVENTOS */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* seccion para mostrar la lista de documentos*/
  {
    path: "/documentos/listado",
    name: "documentos",
    meta: {
      title: "Documentos",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/documentos/listado"),
  },
  
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE AUTORIZAR */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* seccion para visualizar la informacion de los documentos */
  {
    path: "/procesos/listado",
    name: "Procesos de pedido",
    meta: {
      title: "Procesos de pedido",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/procesos/listado"),
  },
  
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE ACONDICONAR */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* seccion para visualizar la informacion de los documentos */
  {
    path: "/acondicionar/listado",
    name: "Procesos de acondicionamiento",
    meta: {
      title: "Procesos de acondicionamiento",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/acondicionar/listado"),
  },

  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE BITACORA */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* seccion para visualizar la informacion de los documentos */
  {
    path: "/bitacora/asignar",
    name: "Procesos de asignación mensajeros",
    meta: {
      title: "Procesos asignación a mensajeros",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/bitacora/asignar"),
  },

  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE BITACORA */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* seccion para visualizar la informacion de los documentos */
  {
    path: "/bitacora/bitacora",
    name: "Proceso bitacora",
    meta: {
      title: "Proceso bitacora",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/bitacora/bitacora"),
  },

  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* SECCION DE RECEPCION */
  /* ///////////////////////////////////////////////////////////////////////////////////// */
  /* seccion para visualizar la informacion de los documentos */
  {
    path: "/recepcion/listado",
    name: "Recepeción",
    meta: {
      title: "Recepeción",
      authRequired: false,///con esta linea se activa o se desactiva la validacion de login
    },
    component: () => import("../views/recepcion/listado"),
  },
  
];