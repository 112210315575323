import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n';

import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import '@/assets/scss/config/minimal/app.scss';
import '@vueform/slider/themes/default.css';

import "leaflet/dist/leaflet.css";

//import VueApexCharts from "vue3-apexcharts";
//import Maska from 'maska';
import VueFeather from 'vue-feather';
//import Particles from "particles.vue3";
//import 'vue-suggestion/dist/vue-suggestion.css';
//import VueSuggestion from 'vue-suggestion';
//import Toaster from './meforma/vue-toaster';
/* isntancionamos el plugin para el manejo de inactividad */

/* alertas */
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
}), 

createApp(App)
.use(store)
.use(router)
//.use(Toaster)
//.use(VueApexCharts)
.use(BootstrapVue3)
.component(VueFeather.type, VueFeather)
//.use(Maska)
//.use(Particles)
.use(i18n)
.use(vClickOutside).mount('#app')
 
