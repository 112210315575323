import { createStore } from 'vuex'
import modules from './modules'

const store = createStore({
  modules,
  /* esta variables se modifican para la seccion de producccion */
  strict: true,
  /* strict: process.env.NODE_ENV !== 'production', */
  /* en esta seccion se declaran los valores que se van a manipular como variables goblales */
  /* /////////////////////////////////////////////////////////////////////////////////////////// */
 
  state () {
    return {
      /* SECCION DE PERSONAS */
      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* valor para el perfil de la persona */
      idPersona: null,/* variable para persona */
      idProducto: null,/* variable para producto */
      tokengenerado: null,

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* SECCION DE CONSTANTES CON VALORES PARA LAS URL */
      /* /////////////////////////////////////////////////////////////////////////////////////// */

      servicioArena: "https://api.gdar.mx/arena/",
      servicioJanus: "https://api.gdar.mx/janus/",
      servicioMarble: "https://api.gdar.mx/marble/",
      servicioTracker: "https://api.gdar.mx/tracker/",
      servcioQuadrant: "https://api.gdar.mx/quadrant/",
      servicioHubble: "https://api.gdar.mx/hubble/",
      servicioLolita: "https://api.gdar.mx/lolita/",
      servicioOrigis: "https://api.gdar.mx/julio/",
      servicioWilson: "https://api.gdar.mx/wilson/",
      servicioJulio: "https://api.gdar.mx/julio/",
      servicioJerry: "https://api.gdar.mx/jerry/",

      /* servicioArena: "https://api.gdar.mx/arena/",
      servicioJanus: "https://api.gdar.mx/janus/",
      servicioMarble: "https://api.gdar.mx/marble/",
      servicioTracker: "https://api.gdar.mx/tracker/",
      servcioQuadrant: "https://api.gdar.mx/quadrant/",
      servicioHubble: "https://api.gdar.mx/hubble/",
      servicioLolita: "https://api.gdar.mx/lolita/",
      servicioOrigis: "https://api.gdar.mx/origis/",
      servicioWilson: "https://api.gdar.mx/wilson/", */


      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* CATALOGO DE VARIABLES PARA EL REDIRECICONAMIENTO DE APIS */
      /* /////////////////////////////////////////////////////////////////////////////////////// */
    
      /* CATALOGOS */
      /* Catalogos generales de personas */
      apiOpcionesCatalogo: "opciones/",
      /* Catalogo de tipo de perfiles */
      apiCatTipoPerfil: "opciones/perfil",
      /* catalogo para tipo de personas */
      apiCatTipoPersona: "opciones/persona",
      /* catalogo para el tipo de ubicacion */
      apiCatTipoUbicacion: "opciones/ubicacion",
      /* catalogo de tipo de telefono */
      apiCatTipoTelefono: "opciones/telefono",
      /* catalogo para los codigo postales */
      apiCatCp: "codigos_postales/",
      /* catalogo para tipo de cuentas */
      apiCatCuenta: "opciones/cuenta",
      /* catalogos de tipo de contacto */
      apiCatContacto: "opciones/medio_contacto",
      /* catalogo de informacion extra */
      apiCatExtra: "opciones/extras",
      /* buscar persona por perfil */
      apiPersonaId: "personas/info/",
      /* mostramos el catalogo de seguradoras */
      apiCatAseguradoras: "aseguradora_list/personas",
      /* mostrar el catalogo de bancos */
      apiCatbancos: "banco_list/personas",
      /* catalogo escolaridad */
      apiCatEscolaridad: "opciones/escolaridad",
      /* catalogo nacionalidad */
      apiCatNacionalidad:"opciones/nacionalidad",
      /* catalogo estado civil */
      apiCatEstadoCivil:"opciones/estado_civil",
      /* catalogo para el uso de direccion */
      apiCatUsoDireccion: "opciones/ubicacion_uso",
      /* obtenemos los  */
      apitipoDocumen: "opciones/documento",
      
      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* ADMIN */
      /* direccion para la creacion de token */
      apiToken: "https://api.gdar.mx/janus/badge",
      /* apiToken: "https://api.gdar.mx/janus/badge", */
      /* direccion para el en mascaramiento de el id de elementos */
      apiAlpha: "admin/",
      /* direccion solo con la api */
      apiArena:"https://api.gdar.mx/arena/",
      apiAlphaMarble:"admin/",
      apiAlphaTrakcer:"https://api.gdar.mx/tracker/",
      /* generar el tokin de inicio */
      apiLogin: "https://api.gdar.mx/janus/sentry",
      /* para la consulta de pills */
      apiPills: "https://api.gdar.mx/pills/",
      
      
      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET PERSONAS*/
      /* api para guardar una nueva persona */
      apiGetPersona: "personas/",
      /* api para guardar una nueva persona full  */
      apiGetPersonaguardarFull:"personas_full/",
      /* api para  ´polizas de seguro */
      apiGetPoliza: "polizas_seguro/",
      /* para buscar tipo de perfil por id */
      apiGetPerfil: "opciones/perfil/",
      /* para trae la informacion general de una persona */
      apiGetInfoGeneral: "personas_full/info/",
      /* para cuentas */
      apiGetCuenta: "cuentas/",
      /* para agregar un nuevo medio de contacto */
      apiGetContactoNuevo: "medios_contacto/",
      /* funcion para informacion adicional */
      apiGetInfoAdicional: "extras/",
      
    
      /* funcion para mostrar los medicos asignados = grupos */
      apiGetInfoGrupos: "grupos",
      apiGetInfoGrupos2: "grupo_list",
      /* funcio para agregar medicos = grupos */
      apiGetInfoGruposagrega: "grupos/",
      /* funcion par amostrar los grupos de una persona  */
      apiGetInfoGruposPersona: "miembros/grupos/",
      /* funcion para mostrar el grupo y sus datos */
      gruposMedicos: "grupos/miembros/",
      /* par amostrar las direcciones de la persona */
      apiGetInfoDir: "direcciones/",
      /* para traer el concentrado de perfiles */
      apiGetPerfiles: "perfiles/",
      /* para mostrar todas las personas */
      personasFull: "persona_list/info",
      /* para busquedas en formulario de person nueva */
      /* general */
      busPersona: "arena/personas/info/",
      /* buscador final de personas */
      busPersonaFin: "personas_full/info/filtros",
      /* buscador por id */
      busPersonaDocumen: "personas_full/info/",
      /* para obtener los nombre de los miembros para las direcciones de actividades*/
      busMiembrosDocu: "grupos/miembros/",
      /* direccion para la validacion de cedula profesional */
      valCedula: "cedula/",
      /* catalogo para los titulos de persona */
      catTipoPersona: "opciones/titulo",
      /* funcion para mostrar el qr del usuario para su token */
      muestroTokenQr: "qr/",
      /* funcion para obtener valor de posicionamiento de mapas  */
      obtengoPosicionMapa: "geo/ubicacion/",
      /* catalogo para motivo de contacto */
      obtengoMotivo: "opciones/motivo_contacto",
      /* para obtener tipo de telefono */
      obtengoTipoTel: "admin/pnn_telefono/",

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET PRODUCTOS */
      /* catalogo de productos */
      catTipoProducto: "opciones/item",
      /* catalogo de cofepris */
      catCofepris: "opciones/cofepris",
      /* catalogo de temperatura_almaceniamienot */
      catTemAlmacenamiento: "opciones/temperatura_almacenamiento",
      /* catalogo de unidad */
      catUnidad: "opciones/unidad",
      /* catalogod e dramake */
      catSDramaje: "opciones/gramaje",
      /* catalogo de canal */
      catCanal: "https://api.gdar.mx/marble/opciones/canal",
      /* busqueda general de productos  */
      busProductos: "items/info/",
      /* para todo general */
      genProductos:"https://api.gdar.mx//marble/",
      /* para mostrar todos los productod */
      genProductosFull:"https://api.gdar.mx/marble/item_list",
      /* para mostrar la informacion princial */
      genProductosDetalle:"https://api.gdar.mx/marble/items_list/info/",
      /* para editar los productod */
      ediProductos:"items/",
      /* para agregar un nuevo producto */
      newProductos:"items/",
      /* para agregar informacion extra */
      newExtra:"extras/",
      /* para mostrar el tipo de informacion extra */
      catInfoExtra:"opciones/extras",
      /* para la consulta de pills */
      catPills:"https://api.gdar.mx/pills/",
      /* consulta final para el buscador final */
      genProductosFinal:"items/info/filtros",
      /* consulta para agregar las condiciones comerciales */
      agregoCondiciones:"condiciones_comerciales/",
      /* consultamos los proyectos para las condiciones comerciales */
      catProyecCondi:"proyecto_list",
      /* consulta para mostrar las condiciones comerciales que tiene el producto */
      listadoCondicionesCom:"condiciones_comerciales/",
      /* para el catalogo de tipo de operaciones */
      catTipoOpe:"opciones/operacion",
      /* obtener el tipo de persona moral */
      catTipoModal:"opciones/titulo_persona_moral",
      /* para la seccion de proyectos */
      proyectoMarble: "proyectos/",
      /* para la carga masiva de productos */
      detallesMasivos: "julio/",
      
      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET CONDICIONES DE PAGO */
      catCondicionesPago:"opciones/condicion_pago",
      catmetodoPago:"opciones/metodo_pago",
      nuevoCat: "opciones/",
      guardarMetodoPa: "actividades/update_pagos_actividad/",
      /* /////////////////////////////////////////////////////////////////////////////////////// */

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET MOVIMIENTOS */
      /* para mostrar las actividades por id de producto */
      muestroActividades:"actividades/info/",
      /* para el catalogo de tipo de movimiento */
      catTipoActividades:"opciones/actividad",
      /* para el catalogo de proyectos desde marble */
      catTipoProyecto:"proyecto_list",
      /* para el catalogo de estatus desde tracker */
      catTipoEstastus:"opciones/estatus",
      /* para el catalogo de canal desde trackaer */
      catTipoCanal:"opciones/canal",
      /* para el catalogo de documento desde tracker */
      catTipoDocumento:"opciones/documento",
      /* para el buscador general */
      buscarGeneral:"actividades/filtros/info",
      /* para crear documentos */
      creoDocumento:"actividades/",
      /* para crear direccion de documentos */
      creoDocumentoDir:"direcciones/",
      /* para eidtar direccion de documentos */
      creoDocumentoDirEditar:"direcciones/",
      /* guardar productos al documento */
      guardoProductoDoc:"detalles/",
      /* mostrar los productos del documento */
      muestroProductoDoc:"detalles/",
      /* para actyualizar los status  */
      cambioEstatus:"actividades/update_status_actividad/",

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET LOGIN */
      getIngreso:"https://api.gdar.mx/janus/",

       /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET Origis*/
      consultaPromosPro: "consulta_hub",
      /* para agregegtar productos en origis */
      guardoProducto: "consulta_hub",

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* VARIABLES PARA EL LOGIN */
      varAud: null,
      varExp: null,
      varIis: null,
      varRoles: null,
      varScope: null,
      varSub: null,
      varToken: null,

       /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* SECCION DE LOLITA */
      usoDeCfdi: "cfdi_list",
      obtenerconstanci: "constancia/",
      obtenerregimen: "regimen_fiscal_list",

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET HUBBLE */
      estatusServicios:"web_sites_status/",
      estatusServiciosGeneral:"web_sites_warning/",

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* GET JERRY */
      RealizarPago:"paypoint",

      /* /////////////////////////////////////////////////////////////////////////////////////// */
      /* ALPHA EMPRESA */
      alfaEmp: "hKxdc"




    }
  },
  /* /////////////////////////////////////////////////////////////////////////////////////////// */
  /* seccion de mutacion es donde las variables van a cambiar de valor */
  mutations:{
    identificarpersona (idPersona,n){ this.state.idPersona = n },//modificacion de variable persona
    identificarProducto (idProducto,n){ this.state.idProducto = n },//modificacion de variable producto
    salgoSi (tokengenerado,n){ this.state.tokengenerado = n },//modificacion de variable producto

    /* mutaciones de datos de login */
    guardosub (varSub,n){ this.state.varSub = n },//se guarda sub 
    
  },
  /* ////////////////////////////////////////////////////////////////////////////////////////// */
  /* acciones para realizar las mutacion de variables */
  actions:{
    modificaIdentificador (context){ context.commit('identificarPersona');},/* para persona */
    modificaIdentificadorProducto (context){ context.commit('identificarProducto');},/* para persona */
    salgosistema (context){ context.commit('salgoSi');},/* para persona */

    /* acciones para l0os valores del login */
    /* para guardar los datos del token */
    modificaSub (context){ context.commit('guardosub');}, 
    
  },
  /* ////////////////////////////////////////////////////////////////////////////////////////// */
  /* seccion de getter que son metodos para llamara los state desde cuando se manda a llamar */
  getters(){}
  /* ////////////////////////////////////////////////////////////////////////////////////////// */
})
export default store

